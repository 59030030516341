<template>
  <div class="mx-3 mx-md-6 mb-6">
    <div v-if="loading" class="pt-8 text-center">
      <v-progress-circular :size="80" color="primary" indeterminate />
      <p class="pt-8">Loading...</p>
    </div>

    <div v-else>
      <div class="d-flex align-center">
        <!-- <div v-if="tenant.logo !== ''">
          <v-avatar v-if="tenant.logo" size="62" class="mr-6">
            <v-img :src="cdnURL + '/' + tenant.logo"></v-img>
          </v-avatar>
        </div> -->
        <div>
          <div class="headline">{{ tenant.name }}</div>
          <!-- <div class="text-body-2">
            Your avatar shows up in your public profile and your team
            notificaitons
          </div> -->
        </div>
      </div>

      <v-card :class="cardClass">
        <v-row>
          <v-col cols="6">
            <div class="text-h5 mb-7">Basic Information</div>
          </v-col>
          <v-col cols="6" class="d-flex flex-row-reverse">
            <v-dialog
              v-model="dialog"
              fullscreen
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="onResEdit"
                  v-bind="attrs"
                  v-on="on"
                  text
                  color="primary"
                >
                  <v-icon small left>mdi-pencil</v-icon>
                  <span class="text-button">Edit Information</span>
                </v-btn>
              </template>
              <v-card color="#212429">
                <v-toolbar
                  color="#151D21"
                  class="px-0 px-md-16"
                  style="position: sticky; top: 0; z-index: 999"
                >
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-card-title>
                    <span class="headline">Restaurant Info</span>
                  </v-card-title>
                </v-toolbar>

                <v-card-text class="px-3">
                  <v-container class="px-0">
                    <RestaurantForm
                      :isDialog="true"
                      :tenant="tenant"
                      @tenantEditInfo="getTenantEdit($event)"
                      class="mx-0 mx-md-7"
                    />
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <div class="text-caption grey--text mb-2">Restaurant Name</div>
        <div class="text-body-1 mb-6">{{ tenant.name }}</div>
        <div class="text-caption grey--text mb-2">Restaurant Address</div>
        <div class="text-body-1 mb-6">{{ tenant.users[0].address }}</div>
        <v-row>
          <v-col cols="6">
            <div class="text-caption grey--text mb-2">Owner Name</div>
            <div class="text-body-1 mb-0">{{ tenant.users[0].name }}</div>
          </v-col>
          <v-col cols="6">
            <div class="text-caption grey--text mb-2">Types of Restaurant</div>
            <div class="text-body-1 mb-0">{{ tenant.types_of_restaurant }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="6">
            <div class="text-caption grey--text mb-2">Package Name</div>
            <div class="text-body-1 mb-6">
              {{ tenant.subscription.packages[0].name }}
            </div>
          </v-col>
          <v-col cols="4" md="4">
            <div class="text-caption grey--text mb-2">No of POS</div>
            <div class="text-body-1 mb-6">
              {{ tenant.subscription.number_of_pos }}
            </div>
          </v-col>
          <v-col cols="4" md="2" class="text-right">
            <v-dialog v-model="dialogPackage" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text color="primary">
                  <v-icon small left>mdi-pencil</v-icon>
                  <span class="text-button">Edit</span>
                </v-btn>
              </template>
              <v-form ref="packageForm" v-model="packageForm">
                <v-card>
                  <v-card-title>
                    <span class="headline">Package Info</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            v-model="restaurantPackage.package_id"
                            :items="packages"
                            item-text="packageName"
                            item-value="packageId"
                            label="Package Name"
                            :rules="itemRules"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="restaurantPackage.pos"
                            type="number"
                            label="No. of POS"
                            :rules="posRules"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogPackage = false">
                      Close
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="!packageForm || loadingPk"
                      :loading="loadingPk"
                      text
                      @click="UpdatePackage"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>

      <v-card :class="cardClass">
        <v-row>
          <v-col cols="6">
            <div class="text-h5 mb-7">Security Information</div>
          </v-col>
          <v-col cols="6" class="d-flex flex-row-reverse">
            <v-dialog
              v-model="dialogPassword"
              fullscreen
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="onResEdit"
                  v-bind="attrs"
                  v-on="on"
                  text
                  color="primary"
                >
                  <v-icon small left>mdi-pencil</v-icon>
                  <span class="text-button">Update Password</span>
                </v-btn>
              </template>
              <v-card color="#212429">
                <v-toolbar
                  color="#151D21"
                  class="px-0 px-md-16"
                  style="position: sticky; top: 0; z-index: 999"
                >
                  <v-btn icon dark @click="dialogPassword = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-card-title>
                    <span class="headline">New Password</span>
                  </v-card-title>
                </v-toolbar>

                <v-card-text class="px-3">
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    label="Password"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    required
                  ></v-text-field>
                </v-card-text>
                <v-card-actions class="pr-0">
                  <v-spacer></v-spacer>
                  <v-btn :disabled='loadingPassword || (password !== confirmPassword)' :loading="loadingPassword" class="primary mt-4" @click="resetPassword">
                    Update Password
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <div class="text-caption grey--text mb-2">Owner NID</div>
        <div class="text-body-1 mb-6">{{ tenant.owner_nid }}</div>
        <v-row>
          <v-col cols="6">
            <div class="text-caption grey--text mb-2">Contact No.</div>
            <div class="text-body-1 mb-6">{{ tenant.users[0].cell }}</div>
            <div class="text-caption grey--text mb-2">Bin No</div>
            <div class="text-body-1 mb-6">
              {{ Number(tenant.bin_no) === 0 ? "Not set" : tenant.bin_no }}
            </div>
          </v-col>
          <v-col cols="6">
            <div class="text-caption grey--text mb-2">Email</div>
            <div class="text-body-1 mb-6">{{ tenant.users[0].email }}</div>
            <div class="text-caption grey--text mb-2">Mushak No</div>
            <div class="text-body-1 mb-6">
              {{ Number(tenant.musak_no) === 0 ? "Not set" : tenant.musak_no }}
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-card :class="cardClass">
        <v-row>
          <v-col cols="6">
            <div class="text-h5 mb-7">Admin Information</div>
          </v-col>
          <v-col cols="6" class="d-flex flex-row-reverse">
            <v-dialog v-model="dialogOnboarding" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text color="primary">
                  <v-icon small left>mdi-pencil</v-icon>
                  <span class="text-button">Edit</span>
                </v-btn>
              </template>
              <v-form ref="onboardingForm" v-model="onboardingForm">
                <v-card>
                  <v-card-title>
                    <span class="headline">Admin Information</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="adminInfo.onboarded_by"
                            type="text"
                            label="Onboarding By"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="adminInfo.onboarding_date"
                            type="date"
                            label="Onboarding Date"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="adminInfo.branch_address"
                            type="text"
                            label="Branch Addresses"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogOnboarding = false">
                      Close
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="!onboardingForm || loadingOn"
                      :loading="loadingOn"
                      text
                      @click="UpdateOnboarding"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <div class="text-caption grey--text mb-2">Onboarding By</div>
            <div class="text-body-1 mb-6">
              {{ tenant.onboarded_by ? tenant.onboarded_by: 'None' }}
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-caption grey--text mb-2">Onboarding Date</div>
            <div class="text-body-1 mb-6">
              {{ tenant.onboarding_date ? tenant.onboarding_date : 'None' }}
            </div>
          </v-col>
          <v-col cols="12">
            <div class="text-caption grey--text mb-2">Adresses</div>
            <div class="text-body-1 mb-6">
              {{ tenant.branch_address ? tenant.branch_address : 'None' }}
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      timeout="3000"
      top
      right
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import store from "../../../store";
import RestaurantForm from "../../RestaurantForm";
import packages from "../../../dummy/packages";
import { eventBus } from "../../../main";
export default {
  // props: ["singleTenant"],
  components: {
    RestaurantForm,
  },
  data() {
    return {
      cardClass: "mt-6 pa-6 card",
      loading: true,
      tenant: [],
      dialog: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      dialogPassword: false,
      showPassword: false,
      loadingPassword: false,
      dialogPackage: false,
      password: '',
      confirmPassword: '',
      dialogOnboarding: false,
      restaurantPackage: {
        package_id: null,
        pos: null,
      },
      adminInfo: {
        onboarded_by: null,
        onboarding_date: null,
        branch_address: ''
      },
      packages,
      posRules: [(v) => !!v || "POS is required"],
      itemRules: [(v) => !!v || "Item is required"],
      packageForm: false,
      onboardingForm: false,
      loadingPk: false,
      passwordRules: [
        (v) => !!v || 'Please type password.',
        (v) => (v && v.length >= 6) || 'minimum 6 characters',
      ],
      loadingOn: false,

      singleTenantId: this.$route.params.id,
      cdnURL: process.env.VUE_APP_CDN_URL,
    };
  },
  created() {
    // console.log('Restaurant Props singleTenant',this.singleTenant)
    // console.log(this.singleTenant)
    // this.getSingleTenant();
  },
  mounted() {
    // const tenantData = store.getters.singleTenant
    // console.log('tenant', tenantData)
    this.getSingleTenant();
  },
  methods: {
    resetPassword() {
      this.loadingPassword = true
      const payload = {
        password: this.password,
        user_id: this.tenant.users[0].id
      }
      store
          .dispatch("resetPassword", payload)
          .then((res) => {
            if (res.status <= 201) {
              this.loadingPassword = false;
              this.dialogPassword = false;
              this.password = ''
              this.confirmPassword = ''
              this.snackbar = true;
              this.snackbarColor = "#31A274";
              this.snackbarText = "Password successfully updated";
            }
          })
          // this is not working, error not entering into catch; so put it in then()
          .catch((err) => {
            console.log(err);
            this.dialogPassword = false;
            this.loadingPassword = false;
            this.password = ''
            this.confirmPassword = ''
            this.snackbar = true;
            this.snackbarColor = "#FF5252";
            this.snackbarText = "Something Went Wrong!";
          });
    },
    UpdatePackage() {
      if (this.$refs.packageForm.validate()) {
        this.loadingPk = true;
        this.packageForm = false;
        // console.log(this.restaurantPackage.package_id, this.restaurantPackage.pos)
        const payload = Object.assign({}, this.restaurantPackage);
        payload.tenant_id = this.tenant.id;
        store
          .dispatch("upadatePackage", payload)
          .then((res) => {
            // console.log(res)
            if (res.status <= 201) {
              this.getSingleTenant();
              this.loadingPk = false;
              this.dialogPackage = false;
              this.snackbar = true;
              this.snackbarColor = "#31A274";
              this.snackbarText = "Package successfully updated";
              this.$refs.packageForm.reset();
            } else {
              // console.log('error in then res')
              this.packageForm = true;
              this.loadingPk = false;
              this.snackbar = true;
              this.snackbarColor = "#FF5252";
              this.snackbarText = "Something Went Wrong!";
            }
          })
          // this is not working, error not entering into catch; so put it in then()
          .catch((err) => {
            console.log(err);
            this.packageForm = true;
            this.loadingPk = false;
            this.snackbar = true;
            this.snackbarColor = "#FF5252";
            this.snackbarText = "Something Went Wrong!";
          });
      }
    },
    UpdateOnboarding () {
      this.loadingOn = true;
      this.onboardingForm = false;
      const payload = Object.assign({}, this.adminInfo);
      payload.tenant_id = this.tenant.id;
      store.dispatch("updateAdminInfo", payload)
      .then((res) => {
        if (res.status <= 201) {
          this.getSingleTenant();
          this.loadingOn = false;
          this.dialogOnboarding = false;
          this.snackbar = true;
          this.snackbarColor = "#31A274";
          this.snackbarText = "Admin info successfully updated";
          this.$refs.onboardingForm.reset();
        } else {
          this.onboardingForm = true;
          this.loadingOn = false;
          this.snackbar = true;
          this.snackbarColor = "#FF5252";
          this.snackbarText = "Something Went Wrong!";
        }
      })
      .catch((err) => {
        console.log(err);
        this.onboardingForm = true;
        this.loadingOn = false;
        this.snackbar = true;
        this.snackbarColor = "#FF5252";
        this.snackbarText = "Something Went Wrong!";
      });
    },
    getTenantEdit(res) {
      this.getSingleTenant();
      // console.log('getting response from restaurant', res, 'snackbar status -',this.snackbar)
      this.dialog = false;
      this.snackbar = true;
      this.snackbarColor = res.snackbarColor;
      this.snackbarText = res.snackbarText;
    },
    onResEdit() {
      this.dialog = true
      // console.log("Restaurant Edit clicked", this.tenant);
      // this.getSingleTenant();
      // console.log('clicked',this.tenant)
    },
    getSingleTenant() {
      store.dispatch("getSingleTenant", this.singleTenantId).then((res) => {
        // console.log('single restaurant >>> ', res)
        this.loading = false;
        if (res.status === 404) {
          this.tenant = [];
          eventBus.$emit("empty-tenant");
          return;
        }
        // console.log('single tenant response',res.data.data.tenant)
        this.tenant = res.data.data.tenant;
        this.$emit("tenant", this.tenant);
        // console.log('tenant single', this.tenant)
        store.dispatch("tenantSlug", this.tenant.slug);
        this.restaurantPackage.package_id = this.tenant.subscription.packages[0].id;
        this.restaurantPackage.pos = this.tenant.subscription.number_of_pos;
        this.adminInfo.onboarded_by = this.tenant.onboarded_by;
        this.adminInfo.onboarding_date = this.tenant.onboarding_date;
        this.adminInfo.branch_address = this.tenant.branch_address;
      });
    },
  },
};
</script>
